/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Cristian Valencia Agudelo                                     ###### 
###### @date: Enero 2024                                                      ###### 
##################################################################################*/

import { Role } from "@/router/role.js";

// import expedienteRoutes from './expedientes/expediente/routes/expediente.routes.js';
// import documentManagementRoutes from "./document-management/routes/documentManagement.routes.js";

const ExpedienteServicesMain = () => import('./expedienteServicesMain.vue');
const Formulas = () => import('./formulas/Formulas.vue');
const Digitalizador = () => import('./digitalizador/Digitalizador.vue');
const Expedientes = () => import('./expedientes/Expedientes.vue');
const Expediente = () => import('./expediente/Expediente.vue');
const CargaMasiva = () => import('./cargamasiva/CargaMasiva.vue');
const RolesExpedientes = () => import('./roles-expedientes/RolesExpedientes.vue');

const Parametrizacion = () => import('./parametrizacion/Parametrizacion.vue');
const Bodegas = () => import('./bodegas/Bodegas.vue');

// const ExpedientesServicesMain = () => import('./ExpedientesServicesMain.vue');

const expedienteMainRoutes = [
  {
    path: '/modules/expediente',
    name: 'modules.expedienteMain',
    component: ExpedienteServicesMain,
    children: [
      {
        path: 'bandeja',
        name: 'modules.expedienteMain.bandeja',
        component: Formulas,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Digitalizador, Role.Expediente_Regente, Role.Expediente_Caja] }
      },
      {
        path: 'digitalizador',
        name: 'modules.expedienteMain.digitalizador',
        component: Digitalizador,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Digitalizador, Role.Expediente_Regente] }
      },
      {
        path: 'roles-expedientes',
        name: 'modules.expedienteMain.roles-expedientes',
        component: RolesExpedientes,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Regente] }
      },
      {
        path: 'cargamasiva',
        name: 'modules.expedienteMain.cargamasiva',
        component: CargaMasiva,
        meta: { authorize: [Role.Expediente_Administrador] }
      },
      {
        path: 'expedientes',
        name: 'modules.expedienteMain.expedientes',
        component: Expedientes,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Auditor, Role.Expediente_Radicador, Role.Expediente_Caja, Role.Expediente_Digitalizador, Role.Expediente_Regente] }
      },
      {
        path: 'expediente/:id',
        name: 'modules.expedienteMain.expediente',
        component: Expediente,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Auditor, Role.Expediente_Radicador, Role.Expediente_Caja, Role.Expediente_Digitalizador, Role.Expediente_Regente] }
      },
      {
        path: 'parametrizacion',
        name: 'modules.expedienteMain.parametrizacion',
        component: Parametrizacion,
        meta: { authorize: Role.Expediente_Administrador }
      },
      {
        path: 'bodegas',
        name: 'modules.expedienteMain.bodegas',
        component: Bodegas,
        meta: { authorize: Role.Expediente_Administrador }
      }
    ],
    meta: { authorize: Role.Expediente }
  }
  // ,{
  //   path: '/modules/expedientes',
  //   name: 'modules.expedientesServicesMain',
  //   component: ExpedientesServicesMain,
  //   children: [
  //     // ...digitalizadorRoutes,
  //     // ...expedienteRoutes

  //   ],
  //   meta: { authorize: Role.ServiciosFarmaceuticos }
  // },
];


export default expedienteMainRoutes;