/*##################################################################################
###### HERINCO                                                                ######
###### @author: John David Vásquez Serna                                      ######
###### @date: Febrero 2025                                                    ######
##################################################################################*/

const Maestro = () => import('../Maestro.vue');
const Homologacion = () => import('../Homologacion.vue');

import { Role } from "@/router/role.js";

const maestroRoutes = [
  {
    path: 'maestro',
    name: 'modules.settings.maestro',
    component: Maestro,
    children: [
      {
        path: 'homologacion',
        name: 'modules.settings.maestro.homologacion',
        component: Homologacion,
        meta: { authorize: Role.maestroRoutes && Role.Configuraciones_Maestro_Homologacion }
      },
    ],
    meta: { authorize: Role.maestroRoutes }
  },

];

export default maestroRoutes;
