/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Tracking = () => import('../Tracking.vue');
const Rastreo = () => import('../Rastreo.vue');
const AuditoriaTransporte = () => import('../AuditoriaTransporte.vue');
import { Role } from "@/router/role.js";


const trackingRoutes = [
  {
    path: 'tracking',
    name: 'modules.logistics.tracking',
    component: Tracking,
    children: [
      {
        path: 'rastreo',
        name: 'modules.logistics.tracking.rastreo',
        component: Rastreo,
        meta: { authorize: Role.Logistica_Tracking && Role.Logistica_Tracking_Rastreo }
      },
      {
        path: 'auditoriaTransporte',
        name: 'modules.logistics.tracking.auditoriaTransporte',
        component: AuditoriaTransporte,
        meta: { authorize: Role.Logistica_Tracking && Role.Logistica_Tracking_Rastreo }
      },

    ],
    meta: { authorize: Role.Logistica_Tracking }
  },
];


export default trackingRoutes;