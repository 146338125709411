<template>
  <v-container class="py-6">
    <!-- Tarjeta Principal -->
    <v-card class="elevation-12 px-6 py-4">
      <v-card-title class="text-h5 font-weight-bold text-blue-darken-3">
        Bienvenido a HERINCOHAN 4.0
      </v-card-title>
      <v-card-subtitle class="text-grey-darken-1">
        2022-JUN-06 • Notas de versión
      </v-card-subtitle>

      <!-- Imagen y Descripción -->
      <v-row align="center" class="mt-4">
        <v-col cols="12" sm="2" class="text-center">
          <v-img src="@/assets/herinco-small.png" contain max-width="80" class="mx-auto"></v-img>
        </v-col>
        <v-col cols="12" sm="10">
          <p class="text-body-1">
            HERINCOHAN 4.0 es la evolución del sistema HERINCO, diseñado para la gestión logística y farmacéutica de COHAN. Basado en arquitecturas de última generación, proporciona rendimiento óptimo y una experiencia de usuario excepcional.
          </p>
        </v-col>
      </v-row>

      <!-- Características -->
      <v-divider class="my-4"></v-divider>
      <v-card-text>
        <v-row>
          <v-col v-for="(item, index) in features" :key="index" cols="12" sm="6">
            <v-chip class="mb-2" color="blue lighten-2" text-color="white">
              <v-icon left>mdi-check-circle</v-icon>
              {{ item }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Botón Ver Detalles -->
      <v-card-actions class="justify-end">
        <v-btn color="primary" dark @click="showDetails = true">
          <v-icon left>mdi-information</v-icon>
          Ver Detalles
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Modal de Detalles -->
    <v-dialog v-model="showDetails" max-width="600">
      <v-card>
        <v-card-title class="text-h5">📌 Detalles de HERINCOHAN 4.0</v-card-title>
        <v-card-text>
          <ul>
            <li><strong>Arquitectura:</strong> Basada en microservicios para mayor escalabilidad.</li>
            <li><strong>Seguridad:</strong> Módulo de autenticación robusto.</li>
            <li><strong>Integraciones:</strong> Compatible con ECM Docuware y Herinco 1.0.</li>
            <li><strong>Analítica:</strong> Tableros de datos en Power BI.</li>
          </ul>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red darken-1" text @click="showDetails = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showDetails: false,
      features: [
        "Módulo de seguridad y autenticación",
        "Configuración de medios de picking en farmacia",
        "Sistematización de dispensaciones",
        "Integración con Docuware y auditoría de facturación",
        "Visualización de datos con Power BI"
      ]
    };
  }
};
</script>

<style scoped>
.v-btn {
  transition: transform 0.2s ease-in-out;
}
.v-btn:hover {
  transform: scale(1.05);
}
</style>
