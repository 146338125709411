import Vue from 'vue'
import App from './AppAffiliates.vue'
import router from './router-external'
import store from './store'
import vuetify from './plugins/vuetify'
import Keycloak from 'keycloak-js';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from "axios";
import JsonCSV from 'vue-json-to-csv'
import ModelSelect from 'vue-search-select/dist/VueSearchSelect.css'
import AffiliatesLogin from './views/modules/login-affiliates/AffiliatesLogin.vue';
import ValidarAfiliado from './views/modules/login-affiliates/ValidarAfiliado.vue';
import RegistroAfiliado from './views/modules/login-affiliates/RegistroAfiliado.vue';
import CorreoAfiliado from './views/modules/login-affiliates/CorreoAfiliado.vue';

Vue.config.productionTip = false;

// Obtener el entorno actual desde .env
const env = process.env.VUE_APP_ENV.toUpperCase(); // "LOCAL", "DEV", "PROD"

// Configurar las URLs según el entorno
const urlBaseGateway = process.env[`VUE_APP_URL_GATEWAY_${env}`];
const urlBaseKeycloak = process.env[`VUE_APP_URL_KEYCLOAK_${env}`];
const urlBaseHerinco = process.env[`VUE_APP_URL_HERINCO_${env}`];

/**
 * Valida si un token de acceso es válido.
 * @param {string} token - El token de acceso a validar.
 * @returns {boolean} - Verdadero si el token es válido, de lo contrario, falso.
 */
function validateToken(token) {
    if (!token) {
      return false; 
    }
  
    const parts = token.split('.');
    if (parts.length !== 3) {
      return false; 
    }
  
    const decodedPayload = JSON.parse(atob(parts[1]));
    const expirationTime = decodedPayload.exp * 1000;
    if (Date.now() > expirationTime) {
      return false; 
    }
  
    return true;
}

/**
 * Configura la instancia de Axios para incluir el token de acceso en las solicitudes.
 * @param {*} keycloak - La instancia de Keycloak para obtener el token de acceso.
 * @returns {*} - La instancia de Axios configurada.
 */
function configureAxios(keycloak) {
    const base = axios.create({
        baseURL: urlBaseGateway,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': 'Origin, Accept, X-Requested-With, Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token, Access-Control-Request-Method,Access-Control-Request-Headers',
            'Access-Control-Allow-Credentials': true,
            'Content-Type': 'application/json'
        }
    });

    Vue.prototype.$http = base;
    Vue.prototype.$http.interceptors.request.use(
        config => {
            let accessToken = keycloak.token;
            if (accessToken) {
                config.headers = Object.assign({ Authorization: `Bearer ${accessToken}` }, config.headers);
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    return base;
}
if (window.location.href.includes("external/login")) {
    // Configuración de la instancia de Axios para la página de inicio de sesión
    const base = axios.create({
        baseURL: urlBaseGateway,
        headers: { 
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': 'Origin, Accept, X-Requested-With, Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token, Access-Control-Request-Method,Access-Control-Request-Headers',
            'Content-Type': 'application/json'
        }
    });

    Vue.prototype.$http = base;
    
    Vue.component('downloadCsv', JsonCSV)
    Vue.component('model-select', ModelSelect)
    const token = store.state.auth.token;

    // Verifica si el token de acceso es válido antes de renderizar la página del login
    if (!validateToken(token)) {
        new Vue({
            el: '#app',
            router,
            store,
            vuetify,
            render: h => h(AffiliatesLogin)
        });
    } 

    let isLoggedIn = localStorage.getItem('isLoggedIn') === 'true' ? true : false;
    let keycloak;
    // Observa el cambio en el token de autenticación y configura Keycloak y Axios cuando se actualiza
    const unwatch = store.watch(
        (state) => state.auth.token,
        (newToken) => {
        if (newToken !== null) {
            const newToken = store.state.auth.token;
            if (validateToken(newToken)) {
                let initOptions = {
                    realm: 'herinco',
                    url: urlBaseKeycloak + 'auth',
                    clientId: 'herinco-client',
                    onLoad: 'check-sso',
                    flow: 'implicit'
                };
                keycloak = Keycloak(initOptions);
                keycloak.init({ onLoad: initOptions.onLoad }).then(() => {
                
                    const token = newToken;
                    const parts = token.split('.');
                    const decodedPayload = JSON.parse(atob(parts[1]));
                    const refreshToken = store.state.refreshToken;
                
                    keycloak.token = token;
                    keycloak.realmAccess = decodedPayload.realm_access;
                    keycloak.preferred_username = decodedPayload.preferred_username;
                    keycloak.authenticated = true;
                    keycloak.refreshToken = refreshToken;
                    keycloak.idToken = decodedPayload.sub;
                    keycloak.subject = decodedPayload.sub;

                    keycloak.tokenParsed = {
                        acr: decodedPayload.acr,
                        allowedOrigins: decodedPayload.allowedOrigins,
                        aud: decodedPayload.aud,
                        azp: decodedPayload.azp,
                        email: decodedPayload.email,
                        email_verified: decodedPayload.email_verified,
                        exp: decodedPayload.exp,
                        family_name: decodedPayload.family_name,
                        given_name: decodedPayload.given_name,
                        iat: decodedPayload.iat,
                        iss: decodedPayload.iss,
                        jti: decodedPayload.jti,
                        name: decodedPayload.name,
                        preferred_username: decodedPayload.preferred_username,
                        realm_access: decodedPayload.realm_access,
                        resource_access: decodedPayload.resource_access,
                        scope: decodedPayload.scope,
                        session_state: decodedPayload.session_state,
                        sid: decodedPayload.sid,
                        sub: decodedPayload.sub,
                        typ: decodedPayload.typ,
                    };

                    store.commit('updateAuth', { token: keycloak.token, roles: keycloak.tokenParsed.realm_access.roles, username: keycloak.tokenParsed.preferred_username, keycloak: keycloak });
                    
                    Vue.prototype.$urlBaseHerinco = urlBaseHerinco;
                    Vue.prototype.$http = configureAxios(keycloak);

                    const bus = new Vue();
                    Vue.prototype.$bus = bus;
                    Vue.component('downloadCsv', JsonCSV)
                    Vue.component('model-select', ModelSelect)
                    new Vue({
                        el: '#app',
                        router,
                        store,
                        vuetify,
                        render: h => h(App, {}),
                        mounted() {
                            isLoggedIn = true;
                            localStorage.setItem('isLoggedIn', 'true');
                        }
                    });
                    refreshFunction();
                    unwatch();
                });
            }
        }
    });

    const refreshInterval = 5 * 60 * 1000; // Intervalo de actualización del token (5 minutos)
    const checkInterval = 30000; // Intervalo de verificación (30 segundos)

    // Función asincrónica que maneja la actualización del token de autenticación.
    const refreshFunction = async () => {
        try {
            // Verifica si el usuario está autenticado
            if (!isLoggedIn) {
                return; 
            }
            const refreshToken = store.state.refreshToken;
            Vue.prototype.$urlBaseHerinco = urlBaseHerinco;
            const base = axios.create({ baseURL: urlBaseGateway, headers: { 'Content-Type': 'application/json' } });
            Vue.prototype.$http = base;
            // Realiza una solicitud POST para obtener un nuevo token de acceso utilizando el token de actualización
            const response = await Vue.prototype.$http.post(
                `${urlBaseKeycloak}auth/realms/herinco/protocol/openid-connect/token`,
                `client_id=herinco-client&grant_type=refresh_token&refresh_token=${refreshToken}`,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded', }, }
            );
            if (response.data && response.data.access_token) {
                store.dispatch('handleAuthentication', response.data.access_token);
                store.dispatch('handleRefresh', response.data.refresh_token);

                const token = response.data.access_token;
                const parts = token.split('.');
                const decodedPayload = JSON.parse(atob(parts[1]));
                const refreshToken = response.data.refresh_token;
                while (!keycloak) {
                    await new Promise(resolve => setTimeout(resolve, 500));
                }

                keycloak.token = token;
                keycloak.realmAccess = decodedPayload.realm_access;
                keycloak.preferred_username = decodedPayload.preferred_username;
                keycloak.authenticated = true;
                keycloak.refreshToken = refreshToken;
                keycloak.idToken = decodedPayload.sub;
                keycloak.subject = decodedPayload.sub;
                keycloak.tokenParsed = {
                    acr: decodedPayload.acr,
                    allowedOrigins: decodedPayload.allowedOrigins,
                    aud: decodedPayload.aud,
                    azp: decodedPayload.azp,
                    email: decodedPayload.email,
                    email_verified: decodedPayload.email_verified,
                    exp: decodedPayload.exp,
                    family_name: decodedPayload.family_name,
                    given_name: decodedPayload.given_name,
                    iat: decodedPayload.iat,
                    iss: decodedPayload.iss,
                    jti: decodedPayload.jti,
                    name: decodedPayload.name,
                    preferred_username: decodedPayload.preferred_username,
                    realm_access: decodedPayload.realm_access,
                    resource_access: decodedPayload.resource_access,
                    scope: decodedPayload.scope,
                    session_state: decodedPayload.session_state,
                    sid: decodedPayload.sid,
                    sub: decodedPayload.sub,
                    typ: decodedPayload.typ,
                };

                store.commit('updateAuth', { token: keycloak.token, roles: keycloak.tokenParsed.realm_access.roles, username: keycloak.tokenParsed.preferred_username, keycloak: keycloak });

                Vue.prototype.$urlBaseHerinco = urlBaseHerinco;
                Vue.prototype.$http = configureAxios(keycloak);

                const expirationTime = decodedPayload.exp * 1000;
                const timeUntilExpiration = expirationTime - Date.now();
                const nextRefresh = Math.max(timeUntilExpiration - refreshInterval, checkInterval);
                setTimeout(refreshFunction, nextRefresh);
            }
        } catch (error) {
            // Maneja cualquier error y lo registra en la tienda Vuex
            store.commit('setError', true);
            console.error('Error al refrescar el token:', error);
        }
    };
    // Inicia la primera actualización del token después de un intervalo de verificación
    setTimeout(refreshFunction, checkInterval);

    // Escucha el evento de carga de la ventana y llama a la función de actualización del token.
    window.addEventListener('load', () => {
        refreshFunction();
    });

} else if (window.location.href.includes("/external/validarAfiliado")) {
    const base = axios.create({
        baseURL: urlBaseGateway,
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    });
    Vue.prototype.$http = base;
    new Vue({
        el: '#app',
        router,
        store,
        vuetify,
        render: h => h(ValidarAfiliado, {})
    });

} else if (window.location.href.includes("/external/registroAfiliado")) {
    const base = axios.create({
        baseURL: urlBaseGateway,
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    });
    Vue.prototype.$http = base;
    new Vue({
        el: '#app',
        router,
        store,
        vuetify,
        render: h => h(RegistroAfiliado, {})
    });

} else if (window.location.href.includes("/external/correoAfiliado")) {
    const base = axios.create({
        baseURL: urlBaseGateway,
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    });
    Vue.prototype.$http = base;
    new Vue({
        el: '#app',
        router,
        store,
        vuetify,
        render: h => h(CorreoAfiliado, {})
    });
}